// import React from 'react';
// import { Link } from 'react-router-dom';
// import NavBar from '../components/Navbar/NavBar';
// // import heroImg from '../images/web-dev.svg';
// import heroImg from '../images/female-without-bg.png';
// const Hero = () => {
//     return (
//         <>
//             <div className="hero" id='hero'>
//                 <div>
//                     <NavBar />
//                 </div>

//                 <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">

//                     <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
//                         <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
//                             {/* <h1 className="mb-5 md:text-5xl text-3xl font-bold text-[#46423E]-900">

//                                Uraal Is A Suportive Travel Agency
//                             </h1> */}
//                             <h1 className="mb-5 md:text-5xl text-3xl font-bold text-[#46423E] mt-3 md:mt-0 sm:mt-0">
//     {/* We build digital solutions to help businesses scale */}
//     Uraal Is A Supportive Travel Agency
// </h1>

//                             <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">Dedicated To Your Safe,Relaxed and Confident Travel</div>
//                             <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
//                                 <Link to="/contact" className="text-white bg-[#46423E] hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
//                                     Book Your Ticket
//                                     <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
//                                 </Link>
//                                 {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
//                                     Get Started
//                                     <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
//                                 </Link> */}
//                                 {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
//                                     Learn More
//                                     <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
//                                 </a> */}
//                             </div>
//                         </div>
//                         <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
//                             <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={heroImg} />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Hero;



// import React from 'react';
// import { Link } from 'react-router-dom';
// import NavBar from '../components/Navbar/NavBar';
// import heroImg from '../images/female-without-bg.png';
// import FlightSearch from '../pages/FlightSearch';

// const Hero = () => {
//   useEffect(() => {
//     AOS.init({ duration: 1000 });
//   }, []);

//   return (
//     <>
//       {/* Hero Section */}
//       <div className="hero" id="hero">
//         <div>
//           <NavBar />
//         </div>

//         <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
//           <div id="hero" className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
//             <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
//               <h1 className="mb-5 md:text-5xl text-3xl font-bold text-[#46423E] mt-3 md:mt-0 sm:mt-0">
//                 Uraal Is A Supportive Travel Agency
//               </h1>
//               <p className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
//                 Dedicated To Your Safe, Relaxed, and Confident Travel
//               </p>
//               <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
//                 <Link
//                   to="/contact"
//                   className="text-white bg-[#46423E] hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
//                 >
//                   Book Your Ticket
//                   <svg
//                     className="w-4 h-4 ml-1"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 20 20"
//                     fill="currentColor"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
//                       clipRule="evenodd"
//                     ></path>
//                   </svg>
//                 </Link>
//               </div>
//             </div>
//             <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
//               <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={heroImg} />
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Introduction Section */}
//       <div id="introduction" className="py-20 px-4 bg-gray-50" data-aos="zoom-in">
//         <div className="max-w-6xl mx-auto text-center">
//           <h2 className="text-4xl font-bold text-[#46423E] mb-4">
//             Welcome to Uraal Travel
//           </h2>
//           <p className="text-lg text-gray-700 mb-6">
//             At Uraal, we understand that travel is more than just a trip; it’s an experience. That's why we provide a range of services that ensure your journey is smooth, secure, and memorable.
//           </p>
//           <Link
//             to="/about"
//             className="text-white bg-[#46423E] hover:bg-blue-800 inline-flex items-center justify-center px-6 py-3 text-lg shadow-xl rounded-2xl"
//           >
//             Learn More
//           </Link>
//         </div>
//       </div>
// <FlightSearch/>
//       {/* Features Section */}
//       <div id="features" className="py-20 bg-white">
//         <div className="max-w-6xl mx-auto text-center">
//           <h2 className="text-4xl font-bold text-[#46423E] mb-8">Our Features</h2>
//           <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//             <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
//               <h3 className="text-2xl font-semibold text-[#46423E] mb-4">Easy Booking</h3>
//               <p className="text-lg text-gray-700">
//                 With Uraal, booking your travel is just a few clicks away. Get your tickets fast and secure.
//               </p>
//             </div>
//             <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
//               <h3 className="text-2xl font-semibold text-[#46423E] mb-4">Secure Payment</h3>
//               <p className="text-lg text-gray-700">
//                 We ensure your payments are processed securely with top encryption and privacy standards.
//               </p>
//             </div>
//             <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
//               <h3 className="text-2xl font-semibold text-[#46423E] mb-4">24/7 Support</h3>
//               <p className="text-lg text-gray-700">
//                 Our support team is always ready to assist you with any questions or issues at any time of the day.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Hero;



import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/female-without-bg.png';
import FlightSearch from '../pages/FlightSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faShieldAlt, faHeadset } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const features = [
    {
      title: 'Easy Booking',
      description:
        'With Uraal, booking your travel is just a few clicks away. Get your tickets fast and secure.',
      icon: faPlane,
    },
    {
      title: 'Secure Payment',
      description:
        'We ensure your payments are processed securely with top encryption and privacy standards.',
      icon: faShieldAlt,
    },
    {
      title: '24/7 Support',
      description:
        'Our support team is always ready to assist you with any questions or issues at any time of the day.',
      icon: faHeadset,
    },
  ];

  return (
    <>
      {/* Hero Section */}
      <div className="hero" id="hero">
        <NavBar />
        <div
          className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
          data-aos="zoom-in"
        >
          <div
            className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left"
          >
            <div
              className="lg:w-1/2 flex flex-col justify-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <h1 className="mb-5 md:text-5xl text-3xl font-bold text-[#46423E] mt-3 md:mt-0 sm:mt-0">
                Uraal Is A Supportive Travel Agency
              </h1>
              <p className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                Dedicated To Your Safe, Relaxed, and Confident Travel
              </p>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <Link
                  to="/contact"
                  className="text-white bg-[#46423E] hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
                >
                  Book Your Ticket
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
            <div
              className="flex lg:justify-end w-full lg:w-1/2"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <img
                alt="hero"
                className="rounded-t float-right duration-1000 w-full"
                src={heroImg}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Introduction Section */}
      <div id="introduction" className="py-20 px-4 bg-gray-50" data-aos="zoom-in">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-[#46423E] mb-4">
            Welcome to Uraal Travel
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            At Uraal, we understand that travel is more than just a trip; it’s an experience. That's
            why we provide a range of services that ensure your journey is smooth, secure, and
            memorable.
          </p>
          <Link
            to="/contact"
            className="text-white bg-[#46423E] hover:bg-blue-800 inline-flex items-center justify-center px-6 py-3 text-lg shadow-xl rounded-2xl"
          >
            Learn More
          </Link>
        </div>
      </div>

      <FlightSearch />

      {/* Features Section */}
      <div id="features" className="py-20 bg-gradient-to-b from-blue-100 to-blue-300">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-[#46423E] mb-8" data-aos="fade-up">
            Our Features
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="p-6 bg-white rounded-lg shadow-lg transform transition duration-500 hover:scale-105"
                data-aos="fade-up"
                data-aos-delay={index * 200}
              >
                <FontAwesomeIcon
                  icon={feature.icon}
                  className="text-blue-500 text-6xl mb-4"
                />
                <h3 className="text-2xl font-semibold text-[#46423E] mb-4">
                  {feature.title}
                </h3>
                <p className="text-lg text-gray-700">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
