import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { keyframes } from "styled-components";

// Animation for fade-in effect
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Wrapper for the full-width background
const FullWidthWrapper = styled.div`
  background-color: #A69B6E; /* Full-width background color */
  padding: 50px 0; /* Padding for visual spacing */
`;

// Main container with white background
const SearchContainer = styled.div`
  background: #fff; /* White background for the container */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: auto;
  font-family: Arial, sans-serif;
  animation: ${fadeIn} 0.6s ease-out; /* Fade-in animation */

  h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
  }

  p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #444;
  }

  .tabs {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
  }

  .tabs button {
    background: #f3f4f6;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    color: #444;
    transition: all 0.3s ease;
  }

  .tabs button.active {
    background: #007bff;
    color: #fff;
  }

  .tabs button:hover {
    transform: scale(1.05); /* Hover animation */
  }

  .inputs {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  input,
  select {
    width: calc(50% - 10px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  input:focus,
  select:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5); /* Focus effect */
  }

  .date-picker {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .fares {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
  }

  .fares label {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  .fares input[type="radio"] {
    accent-color: #007bff;
  }

  .search-btn {
    display: flex;
    justify-content: flex-end;
  }

  .search-btn button {
    background: #ff6600;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
  }

  .search-btn button:hover {
    background: #ff4500;
    transform: scale(1.1); /* Hover effect */
  }
`;

// Sample data for locations with airport names
const locations = [
  { city: "Dhaka", airport: "Shahjalal International Airport (DAC)" },
  { city: "Cox's Bazar", airport: "Cox's Bazar Airport (CXB)" },
  { city: "Chittagong", airport: "Shah Amanat International Airport (CGP)" },
  { city: "Sylhet", airport: "Osmani International Airport (ZYL)" },
  { city: "Rajshahi", airport: "Shah Makhdum Airport (RJH)" },
  // Add more locations and airports as needed
];

const FlightSearch = () => {
  const [tripType, setTripType] = useState("Round Trip");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [fromLocation, setFromLocation] = useState("");
  const [toLocation, setToLocation] = useState("");

  return (
    <FullWidthWrapper>
      
        
    
    <SearchContainer data-aos="zoom-in">
      <h2>Where to Fly?</h2>
      <p>Find Cheap Flights, Airline Tickets in Bangladesh</p>

        {/* Trip Type Tabs */}
        <div className="tabs">
          {["One Way", "Round Trip", "Multi City"].map((type) => (
            <button
              key={type}
              className={tripType === type ? "active" : ""}
              onClick={() => setTripType(type)}
            >
              {type}
            </button>
          ))}
        </div>

        {/* Input Fields */}
        <div className="inputs">
          <select
            value={fromLocation}
            onChange={(e) => setFromLocation(e.target.value)}
            placeholder="From"
          >
            <option value="">Select Departure Location</option>
            {locations.map((location, index) => (
              <option key={index} value={location.city}>
                {location.city} - {location.airport}
              </option>
            ))}
          </select>

          <select
            value={toLocation}
            onChange={(e) => setToLocation(e.target.value)}
            placeholder="To"
          >
            <option value="">Select Arrival Location</option>
            {locations.map((location, index) => (
              <option key={index} value={location.city}>
                {location.city} - {location.airport}
              </option>
            ))}
          </select>

          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="date-picker"
            placeholderText="Departure Date"
          />
          {tripType === "Round Trip" && (
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="date-picker"
              placeholderText="Return Date"
            />
          )}
          <select>
            <option>1 Traveller</option>
            <option>2 Travellers</option>
            <option>3 Travellers</option>
          </select>
          <select>
            <option>Economy</option>
            <option>Business</option>
            <option>First Class</option>
          </select>
        </div>

        {/* Fare Options */}
        <div className="fares">
          <label>
            <input type="radio" name="fare" defaultChecked />
            Regular Fare
          </label>
          <label>
            <input type="radio" name="fare" />
            Student Fare
          </label>
        </div>

        {/* Search Button */}
        <div className="search-btn">
          <button>Search</button>
        </div>
      </SearchContainer>
    </FullWidthWrapper>
  );
};

export default FlightSearch;
