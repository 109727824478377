import React from 'react';
import misionImg from '../images/OJZ2KF0.jpg'; 
import visionImg from '../images/r5xt_tubg_230315.jpg'; 
import { Link } from 'react-router-dom';

const AboutUs = () => {
    return (
        <>
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about'>
                
                {/* Main Intro Section */}
                <div className="flex flex-col py-8 justify-center text-center" data-aos="fade-up">
                    <h3 className="text-3xl text-blue-900 font-bold">At Uraal, We Are Passionate About Making Your Travel Experiences Smooth and Memorable.</h3>
                    <p className='my-3 text-xl text-gray-600 font-semibold'>
                        With years of experience in the industry, we ensure that every step of your journey is taken care of.
                    </p>
                </div>

                {/* Mission and Vision Cards Section */}
                <div className="flex flex-wrap justify-between mt-8" data-aos="zoom-in" data-aos-delay="500">
                    {/* Mission Card */}
                    <div className="lg:w-5/12 p-6 bg-white rounded-lg shadow-lg mb-6 lg:mb-0">
                        <div className="relative">
                            {/* Image inside the card */}
                            <img 
                                alt="Mission" 
                                className="rounded-t-lg w-full h-40 object-contain" 
                                src={misionImg} 
                            />
                        </div>
                        <div className="relative z-10 p-4">
                            <h4 className="text-2xl text-blue-900 font-semibold">Our Mission</h4>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>
                                Our mission is to be your trusted partner in travel, providing not only the best flight deals and visa processing services but also the peace of mind that comes with expert advice and customer care.
                            </p>
                        </div>
                    </div>

                    {/* Vision Card */}
                    <div className="lg:w-5/12 p-6 bg-white rounded-lg shadow-lg mb-6 lg:mb-0">
                        <div className="relative">
                            {/* Image inside the card */}
                            <img 
                                alt="Vision" 
                                className="rounded-t-lg w-full h-40 object-contain" 
                                src={visionImg} 
                            />
                        </div>
                        <div className="relative z-10 p-4">
                            <h4 className="text-2xl text-blue-900 font-semibold">Our Vision</h4>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>
                                We aim to be the leading travel agency, offering personalized travel services that cater to your unique needs.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Contact Button */}
                <div className="text-center mt-6">
                    <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 text-lg shadow-xl rounded-2xl sm:w-auto">
                        Contact us
                        <svg className="w-4 h-4 ml-1 group-hover:translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default AboutUs;
