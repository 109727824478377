import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faClock, faMapMarkerAlt, faInfoCircle, faConciergeBell, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import logo from '../images/Uraal.jpg'; // Logo import
import bgImage from '../images/footer.svg';
const Footer = () => {
  return (
    <>
       <footer
      className="bg-cover bg-center bg-no-repeat text-gray-700 border-t"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
        <div className="px-4 sm:px-6 py-12">
          {/* Grid layout */}
          <div className="grid sm:grid-cols-12 gap-6">

            {/* Logo and About */}
            <div className="col-span-12 lg:col-span-4 text-center">
              <div className="bg-gradient-to-r from-blue-200 to-blue-500 text-white p-8 rounded-lg shadow-md">
                <img
                  src={logo}
                  alt="Uraal Logo"
                  className="h-28 w-28 mx-auto mb-4 rounded-full bg-white p-2"
                />
                <p className="text-lg font-semibold">
                  Dedicated To Your Safe, Relaxed, and Confident Travel.
                </p>
              </div>
            </div>

            {/* Contact Info */}
            <div className="col-span-6 md:col-span-6 lg:col-span-4">
              <h6 className="text-[#013289] text-xl font-bold mb-4">Contact Us</h6>
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-center">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="text-[#013289] mr-3" />
                  299/5, Nazifa (Ground Floor), Sher-E-Bangla Road, Dhanmondi, Dhaka - 1209
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon icon={faClock} className="text-[#013289] mr-3" />
                  <span>
                    <strong>Office Time:</strong> 10 AM - 7 PM <br />
                    <strong>Off Day:</strong> Friday and Public Holidays
                  </span>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon icon={faPhone} className="text-[#013289] mr-3" />
                  +8801841842481 (WhatsApp) | +8801794219596
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon icon={faEnvelope} className="text-[#013289] mr-3" />
                  <a href="mailto:contact.uraal@gmail.com" className="hover:text-gray-900">
                    contact.uraal@gmail.com
                  </a>
                </li>
              </ul>
            </div>

            {/* Quick Links */}
            <div className="col-span-6 md:col-span-3 lg:col-span-2">
              <h6 className="text-[#013289] text-xl font-bold mb-4">Quick Links</h6>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <FontAwesomeIcon icon={faInfoCircle} className="text-[#013289] mr-3" />
                  <HashLink to="#" className="hover:text-gray-900">
                    About Us
                  </HashLink>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon icon={faConciergeBell} className="text-[#013289] mr-3" />
                  <HashLink to="#" className="hover:text-gray-900">
                    Services
                  </HashLink>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon icon={faAddressBook} className="text-[#013289] mr-3" />
                  <HashLink to="#" className="hover:text-gray-900">
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* Follow Us */}
            <div className="col-span-12 lg:col-span-2 text-center">
              <h6 className="text-[#013289] text-xl font-bold mb-4">Follow Us</h6>
              <div className="flex justify-center space-x-6">
                <a
                  href="https://www.facebook.com/profile.php?id=61563557415242&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#013289] hover:text-gray-900"
                >
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a
                  href="https://wa.me/8801841842481"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#013289] hover:text-gray-900"
                >
                  <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                </a>
                <a
                  href="mailto:contact.uraal@gmail.com"
                  className="text-[#013289] hover:text-gray-900"
                >
                  <FontAwesomeIcon icon={faEnvelope} size="2x" />
                </a>
              </div>
            </div>

          </div>

          {/* Footer Bottom */}
          <div className="text-center border-t border-gray-300 mt-8 pt-4">
            <p className="text-sm text-gray-600">
              Copyright &copy; {new Date().getFullYear()}{" "}
              <a href="https://uraal.net/" className="text-[#013289] hover:text-gray-900">
                Uraal
              </a>
              . All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
