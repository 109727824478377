import React, { useState, useEffect } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import emailjs from "emailjs-com";
import Notiflix from "notiflix";

const Contact = () => {
  useDocTitle("Uraal contact");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  // Check if all required fields are filled
  useEffect(() => {
    const allFieldsFilled = firstName && lastName && email && phone && message;
    setIsButtonDisabled(!allFieldsFilled);
  }, [firstName, lastName, email, phone, message]);

  const validateInputs = () => {
    const errors = {};
    if (!firstName) errors.firstName = "First name is required";
    if (!lastName) errors.lastName = "Last name is required";
    if (!email) errors.email = "Email is required";
    if (!phone) errors.phone = "Phone number is required";
    if (!message) errors.message = "Message is required";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    if (!validateInputs()) return;

    setLoading(true);

    try {
      // Sending email via EmailJS
      const templateParams = {
        from_name: `${firstName} ${lastName}`,
        from_email: email,
        phone_number: phone,
        message: message,
      };
      // service_ymr012d
      // template_1png12h
      // 4KxGYSsG0l4Ujzgvj

      // const serviceId = 'service_1knfku9';
      // const templateId = 'template_l3wspa8';
      // const userId = 'iWF8xbb9Wc4LQS11v';
      const serviceId = "service_h65fl2f";
      const templateId = "template_z5kfnnd";
      const userId = "qWDjy0eLiRjVGcYga";
      await emailjs.send(serviceId, templateId, templateParams, userId);

      Notiflix.Report.success(
        "Success",
        "Your message and consultation request have been sent successfully!",
        "Okay"
      );

      // Clear form inputs
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      Notiflix.Report.failure(
        "An error occurred",
        "Your message could not be sent. Please try again later.",
        "Okay"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="contact"
        className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24"
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <form onSubmit={sendEmail}>
            <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
              <div className="flex">
                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
                  Send us a message
                </h1>
              </div>
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                <div>
                  <input
                    name="first_name"
                    className={`w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline ${
                      errors.firstName ? "border-red-500" : ""
                    }`}
                    type="text"
                    placeholder="First Name*"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm">{errors.firstName}</p>
                  )}
                </div>

                <div>
                  <input
                    name="last_name"
                    className={`w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline ${
                      errors.lastName ? "border-red-500" : ""
                    }`}
                    type="text"
                    placeholder="Last Name*"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-sm">{errors.lastName}</p>
                  )}
                </div>

                <div>
                  <input
                    name="email"
                    className={`w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline ${
                      errors.email ? "border-red-500" : ""
                    }`}
                    type="email"
                    placeholder="Email*"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>

                <div>
                  <input
                    name="phone_number"
                    className={`w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline ${
                      errors.phone ? "border-red-500" : ""
                    }`}
                    type="number"
                    placeholder="Phone*"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-sm">{errors.phone}</p>
                  )}
                </div>
              </div>
              <div className="my-4">
                <textarea
                  name="message"
                  placeholder="Message*"
                  className={`w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline ${
                    errors.message ? "border-red-500" : ""
                  }`}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>
              <div className="my-2 w-1/2 lg:w-2/4">
                <button
                  type="submit"
                  id="submitBtn"
                  disabled={isButtonDisabled || loading}
                  className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline relative"
                >
                  {loading ? (
                    <>
                      <span>Sending...</span>
                      <span className="absolute inset-0 flex items-center justify-center">
                        <svg
                          className="w-5 h-5 text-gray-200 animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 12a8 8 0 1 0 16 0A8 8 0 0 0 4 12zm1 0a7 7 0 1 1 14 0A7 7 0 0 1 5 12z"
                          ></path>
                        </svg>
                      </span>
                    </>
                  ) : (
                    "Send Message"
                  )}
                </button>
              </div>
            </div>
          </form>
          <div className="w-full lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
    <div className="flex flex-col text-white">
        {/* Office Address */}
        <div className="flex my-4 w-2/3 lg:w-3/4">
            <div className="flex flex-col">
                <i className="fas fa-map-marker-alt pt-2 pr-2" />
            </div>
            <div className="flex flex-col">
                <h2 className="text-2xl">Office Address</h2>
                <p className="text-gray-400">
                    299/5, Nazifa (Ground Floor), Sher-E-Bangla Road, Dhanmondi, Dhaka - 1209
                </p>
            </div>
        </div>

        {/* Office Timing */}
        <div className="flex my-4 w-2/3 lg:w-3/4">
            <div className="flex flex-col">
                <i className="fas fa-clock pt-2 pr-2" />
            </div>
            <div className="flex flex-col">
                <h2 className="text-2xl">Office Time</h2>
                <p className="text-gray-400">
                    <strong>10 AM - 7 PM</strong>
                </p>
                <p className="text-gray-400">
                    <strong>Off Day:</strong> Friday and Public Holidays
                </p>
            </div>
        </div>

        {/* Call Us */}
        <div className="flex my-4 w-2/3 lg:w-1/2">
            <div className="flex flex-col">
                <i className="fas fa-phone-alt pt-2 pr-2" />
            </div>
            <div className="flex flex-col">
                <h2 className="text-2xl">Call Us</h2>
                <a href="tel:+8801794219596" className="text-gray-400">+8801794219596</a>
                <a href="tel:+8801841842481" className="text-gray-400">+8801841842481 (WhatsApp)</a>
            </div>
        </div>

        {/* Send Email */}
        <div className="flex my-4 w-2/3 lg:w-1/2">
            <div className="flex flex-col">
                <i className="fas fa-envelope pt-2 pr-2" />
            </div>
            <div className="flex flex-col">
                <h2 className="text-2xl">Send an Email</h2>
                <a href="mailto:contact.uraal@gmail.com" className="text-gray-400">contact.uraal@gmail.com</a>
            </div>
        </div>

        {/* Social Media Links */}
        <div className="flex my-4 w-2/3 lg:w-1/2">
            <a
                href="https://www.facebook.com/profile.php?id=61563557415242&mibextid=ZbWKwL"
                target="_blank"
                rel="noreferrer"
                className="rounded-full flex justify-center bg-white h-8 text-blue-900 mx-1"
                title="Facebook"
            >
                <i className="fab fa-facebook-f" />
            </a>
            <a
                href="https://wa.me/8801841842481"
                target="_blank"
                rel="noreferrer"
                className="rounded-full flex justify-center bg-white h-8 text-green-500 mx-1"
                title="WhatsApp"
            >
                <i className="fab fa-whatsapp" />
            </a>
            <a
                href="mailto:contact.uraal@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="rounded-full flex justify-center bg-white h-8 text-blue-700 mx-1"
                title="Email"
            >
                <i className="fas fa-envelope" />
            </a>
        </div>
    </div>
</div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
