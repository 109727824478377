import React from 'react';
import img3 from '../images/time to travel the world (Card (Landscape))_20241207_223216_0000.png'; 
import img2 from '../images/time to travel the world (Card (Landscape))_20241207_223335_0000.png';
import img4 from '../images/time to travel the world (Card (Landscape))_20241207_223406_0000.png';
import img1 from '../images/time to travel the world (Card (Landscape))_20241207_223436_0000.png';

const UraalServices = () => {
  return (
    <div id="services" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Our Services
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            Secure Your Journey with Uraal's Premium Travel Services
          </h2>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            {/* Domestic & International Air Tickets */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="Air Tickets"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img1}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Domestic & International Air Tickets
                </h2>
                <p className="text-md font-medium">
                  Book affordable one-way or round-trip air tickets with ease.
                  Enjoy seamless travel booking for both domestic and
                  international destinations.
                </p>
              </div>
            </div>

            {/* Hotel Bookings Worldwide */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="Hotel Booking"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img2}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Hotel Bookings Worldwide
                </h2>
                <p className="text-md font-medium">
                  Secure accommodations globally with our extensive network of
                  trusted hotel partners. Comfort and convenience guaranteed!
                </p>
              </div>
            </div>

            {/* Customized Tour Packages */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="Tour Packages"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img3}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Customized Tour Packages
                </h2>
                <p className="text-md font-medium">
                  Explore tailored travel packages designed to fit your budget
                  and preferences. Perfect for solo travelers, couples, and
                  groups.
                </p>
              </div>
            </div>

            {/* Visa Assistance */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="Visa Assistance"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img4}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Visa Assistance
                </h2>
                <p className="text-md font-medium">
                  Get expert guidance for your visa applications to simplify
                  complex processes and ensure a smooth experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call-to-Action */}
      {/* <section className="mt-12 bg-blue-900 text-white py-8">
      <section className="mt-12 bg-blue-900 text-white py-8" data-aos="zoom-in">
        <div className="text-center">
          <h3 className="text-2xl lg:text-3xl font-bold">
            Why Book with Uraal?
          </h3>
          <ul className="list-disc list-inside mt-4 text-lg">
            <li>✔ Affordable Domestic & International Flights</li>
            <li>✔ Trusted Global Hotel Partners</li>
            <li>✔ Personalized Tour Packages</li>
            <li>✔ Seamless Visa Processing</li>
          </ul>
          <p className="mt-6 text-xl font-medium">
            🌐 Visit Us: <a href="https://uraal.net" className="underline">uraal.net</a>
          </p>
          <p className="text-xl">
            📞 Call: <a href="tel:01841-842481" className="underline">01841-842481</a>
          </p>
        </div>
      </section> */}
    
    <section className="mt-12 bg-gradient-to-r from-blue-800 via-blue-700 to-blue-900 text-white py-12 overflow-hidden">
  <div className="max-w-6xl mx-auto px-6 md:px-12 animate-fadeIn">
    <h3 className="text-3xl lg:text-4xl font-extrabold text-center mb-8 animate-bounceIn">
      Why Choose <span className="text-yellow-400">Uraal</span>?
    </h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {/* Left Content: Key Features */}
      <ul className="space-y-4 text-lg">
        {[
          "Affordable Domestic & International Flights",
          "Trusted Global Hotel Partners",
          "Personalized Tour Packages",
          "Seamless Visa Processing",
        ].map((item, index) => (
          <li
            key={index}
            className="flex items-start transform transition duration-500 hover:scale-105"
          >
            <span
              className="text-yellow-400 text-2xl mr-3 animate-slideInLeft"
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              ✔
            </span>
            <span className="animate-slideInRight" style={{ animationDelay: `${index * 0.2}s` }}>
              {item}
            </span>
          </li>
        ))}
      </ul>

      {/* Right Content: Contact Info */}
      <div className="flex flex-col justify-center items-start text-lg">
        <p className="mb-4 animate-fadeUp">
          🌐 <strong>Visit Us:</strong>{" "}
          <a href="https://uraal.net" className="underline text-yellow-400 hover:text-yellow-300">
            uraal.net
          </a>
        </p>
        <p className="mb-4 animate-fadeUp" style={{ animationDelay: "0.2s" }}>
          📞 <strong>Call:</strong>{" "}
          <a href="tel:01841-842481" className="underline text-yellow-400 hover:text-yellow-300">
            01841-842481
          </a>
        </p>
        <p className="animate-fadeUp" style={{ animationDelay: "0.4s" }}>
          💼 <strong>Follow Us:</strong>
          <a href="https://www.facebook.com/profile.php?id=61563557415242&mibextid=ZbWKwL" className="underline text-yellow-400 hover:text-yellow-300 ml-2">
            Facebook
          </a>
          ,
          <a href="" className="underline text-yellow-400 hover:text-yellow-300 ml-2">
            Instagram
          </a>
        </p>
      </div>
    </div>
  </div>
</section>

    </div>
  );
};

export default UraalServices;
